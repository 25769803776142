<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'exercises-items' }"
        >
          Exercise Item</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="display-1 font-weight-bold my-5 text-capitalize">
        Add Exercise Item
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mt-6 pa-0">
        <v-form @submit.prevent="submit" ref="form">
          <v-divider class="pb-5"></v-divider>

          <label class="text-field-label">Exercise Item Name</label>
          <v-text-field
            flat
            solo
            required
            counter
            class="mt-2"
            maxlength="255"
            v-model="form.name"
            :error-messages="form.$getError('name')"
          ></v-text-field>
          <v-row>
            <v-col cols="12">
              <div>
                <input v-model="form.photo" hidden />
                <v-img
                  size="100%"
                  class="mb-4"
                  v-if="photoCover"
                  :src="photoCover"
                  :lazy-src="photoCover"
                ></v-img>
                <v-btn @click="showExerciseItemUpload = true" color="secondary">
                  {{ photoCover ? 'Change' : 'Add' }} Photo
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-alert v-if="form.$getError('photo')" type="error" class="mt-5">
            {{ form.$getError('photo')[0] }}
          </v-alert>
          <v-divider class="my-5"></v-divider>
          <div class="mt-4">
            <v-btn type="submit" color="primary" :loading="form.$busy"
              >Submit</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
    <image-cropper v-model="showExerciseItemUpload" @change="imageChanged" />

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import ImageCropper from '@/components/modals/ImageCropper'
import Form from '@/utils/form'
import { mdiChevronLeft } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState } from 'vuex'
import { each } from 'lodash'

export default {
  name: 'ExerciseItemCreate',

  components: {
    AppBarNavIcon,
    ImageCropper,
  },

  data() {
    return {
      photoCover: null,
      showExerciseItemUpload: false,
      showUpload: false,
      form: new Form({
        name: '',
        photo: null,
      }),
      icons: {
        arrowLeft: mdiChevronLeft,
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      exerciseItemDetails: (state) => state.exerciseItem.exerciseItemDetails,
    }),
  },

  methods: {
    ...mapActions({
      storeExerciseItem: 'exerciseItem/storeExerciseItem',
    }),

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        formData.append(key, value)
      })

      this.storeExerciseItem(formData)
        .then(() => {
          this.showSnackbar('Exercise Item successfully created!', 'success')

          setTimeout(() => {
            this.$router.push({
              name: 'exercises-items',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }

          this.form.$busy = false
        })
    },

    imageChanged(image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.photoCover = reader.result
        this.form.photo = image
      }
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },
}
</script>
